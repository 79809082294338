/* You can add global styles to this file, and also import other style files */
@use 'projects/lms/src/assets/scss/variables' as v;
// @use 'projects/lms/src/assets/scss/mixin' as m;
@use '~@angular/material' as mat;
@import '~material-icons/iconfont/material-icons.scss';

html,
body {
  height: 100%;
}

body {
  background: #ffffff;
  margin: 0;
  padding: 0;

  &.mat-typography,
  .p-component {
    font-family: 'Work Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: inherit;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
  }
}

// Scroll Bar
::-webkit-scrollbar {
  background-color: #ffffff;
  height: 9px;
  width: 9px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #afdef7;
  border: 3px solid #ffffff;
  border-radius: 16px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.chat-icon {
  $icon-size: 40px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 24px;
  height: $icon-size;
  line-height: $icon-size;
  width: $icon-size;
}

.chat-small-icon {
  $icon-size: 32px;
  width: $icon-size;
  height: $icon-size;
  line-height: $icon-size;
  color: #ffffff;
  border-radius: 50%;
  font-size: 18px;
}

.core-container {
  background-color: v.$primary-blue-color;
  color: #ffffff;
  min-height: 100vh;
  padding: min(5vw, 40px) 1vw;
  position: relative;

  h1.core-main-title {
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    margin: 30px 0;
    text-align: center;
  }

  h2.core-sub-title {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
  }

  h4.core-caption {
    font-size: 14px;
    font-weight: 470;
    padding-left: 15px;
    text-align-last: center;
  }
}

.underline {
  border-bottom: 4px solid v.$primary-orange-color;
  padding: 0 24px 7px;
}

.cancel {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.75);
  transition: 0.25s;

  &:hover {
    background: v.$primary-blue-color;
    color: #ffffff;
  }
}

.go-back {
  align-items: center;
  color: v.$primary-blue-color;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 500;
  margin-left: -8px;

  span {
    max-width: 30vw;
  }
}

p.breadcrumb {
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 400;

  span {
    opacity: 0.7;

    &.crumb {
      cursor: pointer;

      &.overflow-ellipsis-1 {
        max-width: min(200px, 50vw);
      }
    }

    &:nth-last-child(1) {
      opacity: 1;
      cursor: default;
    }
  }
}

.search {
  display: flex;

  @media #{v.$lt-md} {
    flex-direction: column;
    justify-content: end;
  }

  .p-input-icon-right {
    > .p-inputtext {
      border-radius: 30px;
      width: min(85vw, 300px);
      border: 1px solid rgba(v.$primary-blue-color, 0.38);
    }

    > i:last-of-type {
      right: 0.75rem;
      color: v.$primary-blue-color;
    }
  }

  label {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.overflow-ellipsis {
  &-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-2 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.title-section {
  background-color: v.$primary-blue-color;
  color: #ffffff;
  padding: 10px min(5vw, 30px);

  .go-back {
    color: #ffffff;
  }

  .title {
    h1 {
      font-size: 2.143rem;
      font-weight: 500;
      overflow-wrap: anywhere;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #ffffff;
    }

    p:not(.breadcrumb) {
      font-size: 1.143rem;
    }
  }

  .progress-count {
    font-size: 16px;
  }

  .title-image {
    img {
      display: flex;
      max-width: 100%;
      // padding-top: 5px;
    }
  }
}

.title-section-sub-container {
  align-items: center;
  display: flex;
  flex-basis: 85%;
  gap: 15px;
  justify-content: space-between;
  max-width: 85%;
  min-width: 85%;

  @media #{v.$gt-md} {
    flex-basis: 1100px;
    max-width: 1100px;
    min-width: 1100px;
  }

  @media #{v.$lt-md} {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }

  @media #{v.$xs} {
    flex-basis: 95%;
    max-width: 95%;
    min-width: 95%;
  }
}

.content-section {
  padding: min(5vw, 30px) min(1vw, 30px);
}

.content-section-sub-container {
  display: flex;
  flex-basis: 85%;
  flex-direction: column;
  gap: 15px;
  max-width: 85%;
  min-width: 85%;

  @media #{v.$gt-md} {
    flex-basis: 1100px;
    max-width: 1100px;
    min-width: 1100px;
  }

  @media #{v.$xs} {
    flex-basis: max(95%, 260px);
    max-width: max(95%, 260px);
    min-width: max(95%, 260px);
  }

  &.tabs {
    gap: 0;
  }

  h3.section-title {
    font-size: 1.286rem;
    font-weight: 400;
    margin-bottom: 0px;
  }
}

.content-filter-row {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;

  @media #{v.$lt-md} {
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }
}

.list-buttons-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}

.no-data {
  color: v.$grey-color;
  font-size: 1.143rem;
}

.no-result {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin: 0px;
    color: #958c8c;
  }
}

p-progressbar.batch-progress {
  margin-bottom: 5px;
  width: 50%;

  @media #{v.$xs} {
    width: 80%;
  }

  .p-progressbar {
    background-color: #efefef;

    .p-progressbar-value {
      background: #facd39;
    }
  }
}

.content-section-sub-container {
  display: flex;
  flex-basis: 85%;
  flex-direction: column;
  gap: 30px;
  max-width: 85%;
  min-width: 85%;

  @media #{v.$gt-md} {
    flex-basis: 1100px;
    max-width: 1100px;
    min-width: 1100px;
  }

  @media #{v.$xs} {
    flex-basis: max(95%, 260px);
    max-width: max(95%, 260px);
    min-width: max(95%, 260px);
  }
}

.chat-icon {
  $icon-size: 40px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 24px;
  height: $icon-size;
  line-height: $icon-size;
  width: $icon-size;
}

.badge-wrapper {
  $icon-size: 80px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: $icon-size;

  .icon {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: $icon-size;
    justify-content: center;
    position: relative;
    width: $icon-size;

    img {
      max-width: 100%;
    }

    span {
      color: #ffffff;
      font-size: 1.429rem;
      font-weight: 600;
      position: absolute;
    }
  }

  &.performance .icon span {
    bottom: 12px;
  }

  > span {
    color: v.$grey-color;
    text-align: center;
    width: 100%;

    &.label {
      font-size: 1rem;
    }

    &.sub-label {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.714rem;
    }
  }
}

.count-wrapper {
  $icon-size: 40px;
  align-items: center;
  background: rgb(247 148 29 / 0.1);
  border-radius: 50%;
  color: #f7941d;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 500;
  height: $icon-size;
  justify-content: center;
  width: $icon-size;
}

.library-grid,
.batches-grid,
.job-grid {
  display: grid;
  gap: 30px 35px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  place-items: stretch stretch;

  mat-card {
    height: 100%;
  }
}

.dialog-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 5px;

  .grid-row {
    margin-bottom: 15px;

    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
}

.dialog-row {
  display: flex;
  justify-content: space-between;

  > * {
    width: 100%;
  }

  @media #{v.$lt-md} {
    flex-direction: column;
    gap: 5px;
  }

  @media #{v.$gt-sm} {
    .col- {
      &1 {
        width: 100%;
      }

      &2 {
        width: 47%;
      }

      &3 {
        width: 30%;
      }
    }
  }
}

.dialog-sub-title {
  color: v.$primary-orange-color;
  font-size: 1.143rem;
  margin-bottom: 1rem;
}

.dialog-actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
  justify-content: center;
  margin-top: 10px;
}

// Snack Bar
.snack-bar-container {
  border-left: 15px solid;

  &.info {
    border-color: v.$primary-blue-color;
  }

  &.success {
    border-color: #28a745;
  }

  &.error {
    border-color: v.$error-color;
  }
}

// Error, Page not Found, Access Denied Pages
.error-page-container {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: v.$primary-blue-color;
  flex-direction: column;
  min-height: 100vh;
  padding: min(5vw, 40px) 1vw;

  .mat-card {
    padding: min(5vw, 40px);
    width: min(95vw, 1050px);
  }

  .mat-card-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  }

  img {
    align-self: center;
    justify-self: center;
    width: 80%;
    height: 250px;
    object-fit: contain;
  }

  .content-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    @media #{v.$xs} {
      align-items: center;

      > div {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .title {
    font-size: 2rem;
  }

  .sub-title {
    color: v.$light-grey-color;
    font-weight: 400;

    @media #{v.$lt-md} {
      text-align: justify;
    }
  }
}

#share-class-mates {
  mat-form-field {
    .mat-form-field-flex {
      background-color: #fff;
      border-radius: 34px;
      border: 1px solid #e4f1f8;
      padding-top: 0px;
    }
    mat-icon {
      color: #68bce9;
      font-size: 2em;
      position: relative;
      top: 5px;
    }
    section {
      padding-bottom: 4px;
    }
  }
  div.mat-form-field-wrapper {
    div.mat-form-field-underline {
      display: none !important;
    }
  }
}

#help-tab {
  padding: 0px;
  mat-tab-header {
    width: 30%;
    .mat-tab-label-container {
      .mat-tab-label-content {
        font-size: 1rem;
      }
    }
    .mat-tab-label-content {
      width: 70%;
      justify-content: flex-start;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
  mat-tab-group.mat-tab-group .mat-tab-label {
    text-align: left;
    padding: 25px;
    font-size: 1rem;
    border-bottom: 1px solid #d6d6d6;
  }
  mat-tab-group.mat-tab-group {
    .mat-tab-label-active {
      border-left: 4px solid v.$primary-orange-color;
      font-weight: 600;
      background-color: rgba(247, 149, 29, 0.2);
    }
    mat-ink-bar {
      display: none;
    }
  }
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none;
  }
  .mat-tab-label {
    height: auto;
  }
  .help-header {
    padding: 30px;
  }
  .mat-tab-body-wrapper {
    border-left: 1px solid #d6d6d6;
    padding: 30px;
  }
  #help-device-large p-accordiontab {
    .p-accordion-toggle-icon {
      position: absolute;
      left: 0em;
      top: 26%;
    }
    .pi-chevron-right:before {
      content: ' \002B';
      color: #2696d3;
      font-size: 28px;
    }
    .pi-chevron-down:before {
      content: ' \002D';
      color: #2696d3;
      font-size: 45px;
    }
    .p-accordion-header-text {
      font-size: 16px;
      font-weight: 500;
      color: #77787b;
      padding-left: 10px;
    }
  }

  #help-devices p-accordiontab {
    .pi-chevron-right:before {
      display: none;
    }
    .pi-chevron-down:before {
      display: none;
    }
    .p-accordion-tab {
      .p-accordion-header {
        .p-accordion-header-link {
          background-color: #afdef7;
          border: 1px solid #fff;
          .p-accordion-header-text {
            color: #000;
            font-size: 1rem;
          }
        }
      }
    }
    .p-accordion-tab-active {
      .p-accordion-header {
        .p-accordion-header-link {
          background-color: v.$primary-blue-color;
          .p-accordion-header-text {
            color: #fff;
            font-size: 1rem;
          }
        }
      }
    }
  }

  #help-devices-sub p-accordiontab {
    .p-accordion-tab {
      box-shadow: none;
    }
    .p-accordion-toggle-icon {
      position: absolute;
      left: 3px;
      top: 26%;
    }
    .pi-chevron-right:before {
      display: block;
      content: ' \002B';
      color: #2696d3;
      font-size: 28px;
    }
    .pi-chevron-down:before {
      display: block;
      content: ' \002D';
      color: #2696d3;
      font-size: 45px;
    }
    .p-accordion-tab {
      .p-accordion-header {
        .p-accordion-header-link {
          background-color: #fff;
          .p-accordion-header-text {
            color: #000;
            font-size: 1rem;
            padding-left: 10px;
          }
        }
      }
    }
    .p-accordion-tab-active {
      .p-accordion-header {
        .p-accordion-header-link {
          background-color: #fff;
          .p-accordion-header-text {
            color: #000;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

/* Material */
// Button
button.mat-button,
button.mat-icon-button,
button.mat-stroked-button,
button.mat-flat-button,
button.mat-raised-button {
  border-radius: 30px;
  cursor: pointer;
  font-weight: normal;

  &:disabled {
    cursor: not-allowed;
  }
}

button.mat-button,
button.mat-stroked-button,
button.mat-flat-button,
button.mat-raised-button {
  .mat-button-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .mat-icon {
    $icon-size: 14px;
    font-size: $icon-size;
    height: $icon-size;
    margin-right: 6px;
    width: $icon-size;
  }
}

// Card
mat-card.mat-card {
  border-radius: 10px;
}

// Dialog
mat-dialog-container.mat-dialog-container {
  overflow: unset;
  padding: 0;
  position: relative;

  .close-icon {
    background: v.$light-blue-color;
    border-radius: 50%;
    color: v.$primary-blue-color;
    height: 30px;
    line-height: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;

    .mat-icon {
      font-size: 20px;
      height: 20px;
      line-height: 20px;
      width: 20px;
    }
  }

  $padding: 25px;
  $padding-side: min(5vw, 35px);

  .mat-dialog-title {
    margin: 0;
    margin-bottom: 0.75rem;
    padding: $padding $padding-side 0;

    h3 {
      color: #343a3f;
      font-size: 1.286rem;
      font-weight: 500;
    }
  }

  .mat-dialog-content {
    margin: 0;
    max-height: 75vh;
    padding: 0.5rem $padding-side $padding;
  }
}

// Form Field
.mat-form-field {
  .mat-form-field-label-wrapper {
    line-height: 1.5;
  }
}

// Nav Tab
nav.mat-tab-nav-bar {
  a.mat-tab-link {
    font-size: 18px;
    font-weight: 400;
    min-width: min-content;

    &.mat-tab-label-active {
      color: v.$primary-orange-color;
      font-weight: 500;
    }
  }
}

// Tab
mat-tab-group.mat-tab-group {
  .mat-tab-label {
    font-size: 18px;
    @media(max-width:479px){
      font-size: 16px;
    }
    &.mat-tab-label-active {
      color: v.$primary-orange-color;
      font-weight: 500;
    }
  }

  .mat-tab-body-wrapper {
    padding-top: 0px;
  }
}

/* Prime NG */
@mixin box-shadow($color) {
  box-shadow: inset 0 0 0 1px $color, inset 0 0 0 1px $color, inset 0 0 0 1px $color,
    inset 0 0 0 1px $color;
}

$form-el-height: 36px;
$form-el-font-size: 14px;

.p-disabled,
.p-disabled * {
  cursor: not-allowed !important;
  pointer-events: auto;
}

// Accordian
// .p-accordion-header-text {
//   color: rgba(v.$primary-blue-color, 0.75);
// }

// .p-accordion-tab-active {
//   .p-accordion-header-text {
//     color: v.$primary-blue-color;
//   }
// }

// .p-accordion .p-accordion-header .p-accordion-toggle-icon {
//   color: rgba(0, 0, 0, 0.6);
//   font-size: 14px;
//   height: 14px;
//   width: 14px;
// }

// Autocomplete
p-autocomplete {
  &.ng-dirty.ng-invalid > .p-autocomplete {
    > .p-inputtext {
      border-color: v.$error-color;

      &:enabled:focus {
        @include box-shadow(v.$error-color);
      }
    }

    .p-autocomplete-multiple-container {
      &:not(.p-disabled).p-focus {
        @include box-shadow(v.$error-color);
      }
    }
  }

  &.not-empty {
    .p-autocomplete {
      height: auto;

      .p-inputtext {
        height: auto;
      }
    }
  }
}

.p-autocomplete {
  height: $form-el-height;
  width: 100%;

  .p-inputtext {
    height: $form-el-height;
  }

  .p-autocomplete-multiple-container {
    gap: 5px 8px;

    .p-autocomplete-token {
      background: rgba(v.$primary-blue-color, 0.12);
      color: v.$primary-blue-color;
      margin: 0;
    }

    &:not(.p-disabled).p-focus {
      border-color: v.$primary-blue-color;
      @include box-shadow(v.$primary-blue-color);
    }
  }
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  background: rgba(v.$primary-blue-color, 0.12);
  color: v.$primary-blue-color;
}

// Button
.p-button {
  background: v.$primary-blue-color;

  &:enabled {
    &:hover {
      background: rgba(v.$primary-blue-color, 0.92);
    }

    &:focus {
      background: rgba(v.$primary-blue-color, 0.76);
    }

    &:active {
      background: rgba(v.$primary-blue-color, 0.68);
    }
  }

  &.p-button-outlined {
    color: v.$primary-blue-color;

    &:enabled {
      &:hover {
        background: rgba(v.$primary-blue-color, 0.04);
        color: v.$primary-blue-color;
      }

      &:focus {
        background: rgba(v.$primary-blue-color, 0.12);
      }

      &:active {
        background: rgba(v.$primary-blue-color, 0.16);
        color: v.$primary-blue-color;
      }
    }

    .p-ink {
      background-color: rgba(v.$primary-blue-color, 0.16);
    }
  }

  &.p-button-text {
    color: v.$primary-blue-color;

    &:enabled {
      &:hover {
        background: rgba(v.$primary-blue-color, 0.04);
        color: v.$primary-blue-color;
      }

      &:focus {
        background: rgba(v.$primary-blue-color, 0.12);
      }

      &:active {
        background: rgba(v.$primary-blue-color, 0.16);
        color: v.$primary-blue-color;
      }
    }

    .p-ink {
      background-color: rgba(v.$primary-blue-color, 0.16);
    }
  }

  .p-badge {
    color: v.$primary-blue-color;
  }

  &.p-button-secondary {
    background: v.$primary-orange-color;

    &:enabled {
      &:hover {
        background: rgba(v.$primary-orange-color, 0.92);
      }

      &:focus {
        background: rgba(v.$primary-orange-color, 0.76);
      }

      &:active {
        background: rgba(v.$primary-orange-color, 0.68);
      }
    }

    &.p-button-outlined {
      color: v.$primary-orange-color;

      &:enabled {
        &:hover {
          background: rgba(v.$primary-orange-color, 0.04);
        }

        &:active {
          background: rgba(v.$primary-orange-color, 0.16);
        }
      }
    }

    &.p-button-text {
      color: v.$primary-orange-color;

      &:enabled {
        &:hover {
          background: rgba(v.$primary-orange-color, 0.04);
          color: v.$primary-orange-color;
        }

        &:active {
          background: rgba(v.$primary-orange-color, 0.16);
          color: v.$primary-orange-color;
        }
      }
    }
  }

  &.p-button-link {
    color: v.$primary-blue-color;

    &:enabled {
      &:hover {
        color: v.$primary-blue-color;
      }

      &:active {
        color: v.$primary-blue-color;
      }
    }
  }
}

.p-buttonset.p-button-secondary > .p-button {
  background: v.$primary-orange-color;
  &:enabled {
    &:hover {
      background: rgba(v.$primary-orange-color, 0.92);
    }

    &:focus {
      background: rgba(v.$primary-orange-color, 0.76);
    }

    &:active {
      background: rgba(v.$primary-orange-color, 0.68);
    }
  }

  &.p-button-outlined {
    color: v.$primary-orange-color;

    &:enabled {
      &:hover {
        background: rgba(v.$primary-orange-color, 0.04);
      }

      &:focus {
        background: rgba(v.$primary-orange-color, 0.12);
      }

      &:active {
        background: rgba(v.$primary-orange-color, 0.16);
      }
    }
  }

  &.p-button-text {
    color: v.$primary-orange-color;

    &:enabled {
      &:hover {
        background: rgba(v.$primary-orange-color, 0.04);
        color: v.$primary-orange-color;
      }

      &:active {
        background: rgba(v.$primary-orange-color, 0.16);
        color: v.$primary-orange-color;
      }
    }
  }
}

// Calendar
p-calendar.ng-dirty.ng-invalid {
  > .p-calendar > .p-inputtext {
    border-color: v.$error-color;
  }

  .p-inputtext:enabled:focus {
    @include box-shadow(v.$error-color);
  }

  .p-calendar-w-btn {
    border-color: v.$error-color;
  }

  .p-calendar-w-btn:not(.p-disabled).p-focus {
    @include box-shadow(v.$error-color);
  }
}

.p-calendar-w-btn:not(.p-calendar-disabled).p-focus {
  border-color: v.$primary-blue-color;
  @include box-shadow(v.$primary-blue-color);
}

.p-input-filled {
  .p-calendar-w-btn {
    background-image: linear-gradient(to bottom, v.$primary-blue-color, v.$primary-blue-color),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));

    &:not(.p-calendar-disabled):not(.p-disabled):hover {
      background-image: linear-gradient(to bottom, v.$primary-blue-color, v.$primary-blue-color),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
    }
  }

  p-calendar.ng-dirty.ng-invalid {
    .p-inputtext {
      background-image: linear-gradient(to bottom, v.$error-color, v.$error-color),
        linear-gradient(to bottom, v.$error-color, v.$error-color);
    }

    .p-calendar-w-btn {
      background-image: linear-gradient(to bottom, v.$error-color, v.$error-color),
        linear-gradient(to bottom, v.$error-color, v.$error-color);

      &:not(.p-disabled) {
        &:hover {
          background-image: linear-gradient(to bottom, v.$error-color, v.$error-color),
            linear-gradient(to bottom, v.$error-color, v.$error-color);
        }

        &.p-focus,
        &.p-inputwrapper-focus {
          background-image: linear-gradient(to bottom, v.$error-color, v.$error-color),
            linear-gradient(to bottom, v.$error-color, v.$error-color);
        }
      }
    }
  }
}

.p-datepicker {
  .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
  .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: v.$primary-blue-color;
  }

  table {
    td {
      > span.p-highlight {
        color: v.$primary-blue-color;
        background: rgba(v.$primary-blue-color, 0.12);
      }

      &.p-datepicker-today {
        > span.p-highlight {
          color: v.$primary-blue-color;
          background: rgba(v.$primary-blue-color, 0.12);
        }

        &.p-highlight {
          box-shadow: 0 0 0 1px rgba(v.$primary-blue-color, 0.12);
        }
      }
    }
  }

  .p-monthpicker .p-monthpicker-month.p-highlight {
    color: v.$primary-blue-color;
    background: rgba(v.$primary-blue-color, 0.12);
  }

  .p-yearpicker .p-yearpicker-year.p-highlight {
    color: v.$primary-blue-color;
    background: rgba(v.$primary-blue-color, 0.12);
  }
}

// Checkbox
.p-checkbox {
  .p-checkbox-box {
    &.p-highlight {
      border-color: v.$primary-blue-color;
      background: v.$primary-blue-color;

      &:not(.p-disabled).p-focus {
        border-color: v.$primary-blue-color;
      }
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-focus {
        border-color: #757575;
      }

      &.p-highlight:hover {
        border-color: v.$primary-blue-color;
        background: v.$primary-blue-color;
      }
    }

    &.p-checkbox-focused {
      box-shadow: none; // Needed
    }
  }

  &.p-checkbox-checked:not(.p-checkbox-disabled) {
    &:hover {
      box-shadow: 0 0 1px 10px rgba(v.$primary-blue-color, 0.04);
    }

    &.p-checkbox-focused {
      box-shadow: none; // Needed
    }
  }
}

.p-checkbox-label {
  cursor: pointer;
}

.p-input-filled {
  .p-checkbox {
    &.p-highlight {
      background: v.$primary-blue-color;
    }

    &:not(.p-checkbox-disabled) .p-checkbox-box {
      &.p-highlight:hover {
        background: v.$primary-blue-color;
      }
    }
  }
}

// Chips
p-chips {
  &.ng-dirty.ng-invalid > .p-chips {
    > .p-inputtext {
      border-color: v.$error-color;
    }

    .p-chips-multiple-container:not(.p-disabled).p-focus {
      border-color: v.$primary-blue-color;
      @include box-shadow(v.$error-color);
    }
  }
}

.p-chips {
  height: $form-el-height;
  width: 100%;

  .p-chips-multiple-container {
    .p-chips-token {
      background: rgba(v.$primary-blue-color, 0.12);
      color: v.$primary-blue-color;
    }

    &:not(.p-disabled).p-focus {
      @include box-shadow(v.$primary-blue-color);
    }
  }
}

// Dropdown
p-dropdown {
  .p-dropdown {
    height: $form-el-height;
    line-height: $form-el-font-size;
    width: 100%;

    &:not(.p-disabled).p-focus {
      border-color: v.$primary-blue-color;
      @include box-shadow(v.$primary-blue-color);
    }

    .p-dropdown-trigger-icon {
      font-size: 10px;
    }
  }

  &.ng-dirty.ng-invalid {
    > .p-dropdown {
      border-color: v.$error-color;
    }

    .p-dropdown:not(.p-disabled).p-focus {
      @include box-shadow(v.$error-color);
    }
  }
}

.p-dropdown-panel {
  max-width: 0;

  .p-dropdown-items {
    .p-dropdown-item {
      border-left: 3px #ffffff solid;
      white-space: pre-wrap;

      &.p-highlight,
      &:not(.p-highlight):not(.p-disabled):hover {
        background: #ffffff;
        border-left: 3px v.$primary-blue-color solid;
        color: v.$primary-blue-color;
      }
    }
  }
}

// File Upload
.p-fileupload {
  .p-fileupload-buttonbar {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-bottom: none;

    .p-fileupload-choose {
      border-radius: 30px;
      height: 36px;

      &:not(.p-disabled):hover {
        background: rgba(v.$primary-blue-color, 0.92);
      }

      &:active {
        background: rgba(v.$primary-blue-color, 0.68);
      }

      .p-button-icon-left {
        font-size: 14px;
        height: 14px;
        width: 14px;
      }
    }
  }

  .p-fileupload-content {
    border: 1px solid rgba(0, 0, 0, 0.38);
    padding: 1rem;

    .p-progressbar {
      display: none;
    }

    .p-fileupload-files {
      .p-button.p-button-icon-only {
        background-color: v.$error-color;
        border-radius: 50%;
        height: 30px;
        width: 30px;

        .p-button-icon {
          font-size: 14px;
        }
      }
    }
  }
}

// Float Label
.p-float-label {
  font-size: $form-el-font-size;
  height: $form-el-height;

  &.required label::after {
    color: v.$error-color;
    content: ' *';
  }

  input:focus ~ label,
  .p-inputwrapper-focus ~ label {
    color: v.$primary-blue-color;
  }

  > .ng-invalid.ng-dirty + label {
    color: v.$error-color;
  }
}

// Input
.p-inputtext {
  font-family: 'Work Sans', sans-serif;
  font-size: $form-el-font-size;
  height: $form-el-height;
  line-height: $form-el-font-size;
  width: 100%;

  &:enabled:focus {
    border-color: v.$primary-blue-color;
    @include box-shadow(v.$primary-blue-color);
  }

  &.ng-dirty.ng-invalid {
    border-color: v.$error-color;
  }

  &:enabled:focus.ng-invalid.ng-dirty {
    @include box-shadow(v.$error-color);
  }
}

.p-inputgroup {
  height: $form-el-height;

  i.pi.pi-times {
    cursor: pointer;
    z-index: 999;
  }

  .p-inputgroup-addon {
    &.datepicker {
      border: 1px solid rgba(0, 0, 0, 0.38);
      border-left: 0;
      padding: 0px;
    }
  }
}

// Input Mask
p-inputmask {
  &.ng-dirty.ng-invalid {
    > .p-inputtext {
      border-color: v.$error-color;
    }

    .p-inputtext:enabled:focus {
      @include box-shadow(v.$error-color);
    }
  }
}

// List Box
.p-listbox .p-listbox-list .p-listbox-item {
  &.p-highlight {
    color: v.$primary-blue-color;
    background: rgba(v.$primary-blue-color, 0.12);
  }

  .p-ink {
    background-color: rgba(v.$primary-blue-color, 0.16);
  }

  &:focus.p-highlight {
    background: rgba(v.$primary-blue-color, 0.24);
  }
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: v.$error-color;
}

// Multiselect
p-multiSelect {
  .p-multiselect {
    height: $form-el-height;
    line-height: $form-el-font-size;
    width: 100%;

    &:not(.p-disabled).p-focus {
      border-color: v.$primary-blue-color;
      @include box-shadow(v.$primary-blue-color);
    }

    &.p-multiselect-chip .p-multiselect-token {
      background: rgba(v.$primary-blue-color, 0.12);
      color: v.$primary-blue-color;
    }

    .p-multiselect-trigger-icon {
      font-size: 10px;
    }

    .p-multiselect-items .p-multiselect-item {
      .p-ink {
        background-color: rgba(v.$primary-blue-color, 0.16);
      }

      &:focus.p-highlight {
        background: none; // Needed
      }
    }
  }

  .p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
  }

  &.ng-dirty.ng-invalid > .p-multiselect {
    border-color: v.$error-color;
  }

  &.not-empty {
    .p-multiselect.p-multiselect-chip {
      height: auto;
    }
  }
}

.p-multiselect-panel {
  max-width: 0;

  .p-multiselect-items {
    .p-multiselect-item {
      border-left: 3px #ffffff solid;
      overflow-wrap: anywhere;
      white-space: pre-wrap;

      &.p-highlight,
      &:not(.p-highlight):not(.p-disabled):hover {
        background: #ffffff;
        border-left: 3px v.$primary-blue-color solid;
        color: v.$primary-blue-color;
      }
    }
  }
}

// Overlay Panel
.p-overlaypanel {
  .p-overlaypanel-close {
    background-color: v.$light-blue-color;
    color: v.$primary-blue-color;
    right: 1rem;
    top: 1rem;

    &:enabled:hover {
      background-color: v.$light-blue-color;
      color: v.$primary-blue-color;
    }
  }

  &.profile-picture-overlay {
    border-radius: 10px;
  }
}

// Password
p-password {
  &.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    border-color: v.$error-color;

    &:enabled:focus {
      @include box-shadow(v.$error-color);
    }
  }

  .p-password {
    width: 100%;
  }
}

.p-password-panel {
  &.p-password-meter {
    background: rgba(v.$primary-blue-color, 0.32);
  }

  ul {
    list-style-position: inside;
    padding-inline-start: 14px;

    > li:first-of-type {
      list-style-type: none;
    }
  }
}

// Progress Bar
.p-progressbar {
  $bar-height: 6px;
  background: rgba(v.$grey-color, 0.32);
  border-radius: $bar-height;
  height: $bar-height;

  .p-progressbar-value {
    background: v.$primary-blue-color;
  }

  .p-progressbar-label {
    line-height: $bar-height;
  }
}

// Radio button
p-radiobutton {
  .ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    border-color: v.$error-color;
  }
}

.p-radiobutton {
  .p-radiobutton-box {
    &:not(.p-disabled).p-focus {
      border-color: v.$primary-blue-color;
    }

    &.p-highlight {
      border-color: v.$primary-blue-color;

      &:not(.p-disabled) {
        &:hover {
          border-color: v.$primary-blue-color;
          color: v.$primary-blue-color;
        }

        &.p-focus {
          border-color: v.$primary-blue-color;
        }
      }
    }

    .p-radiobutton-icon {
      background-color: v.$primary-blue-color;
    }
  }

  &.p-radiobutton-checked:not(.p-radiobutton-disabled) {
    &:hover {
      @include box-shadow(v.$primary-blue-color);
    }

    &.p-radiobutton-focused {
      @include box-shadow(v.$primary-blue-color);
    }
  }
}

// Toggle Button
p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: v.$error-color;
}

.file-upload-icon {
  $icon-size: 35px;
  width: $icon-size;
  height: $icon-size;
  line-height: $icon-size;
  color: v.$primary-blue-color;
  background-color: #cee4f2;
  border-radius: 50%;
}

.chat-small-icon {
  $icon-size: 32px;
  width: $icon-size;
  height: $icon-size;
  line-height: $icon-size;
  color: #ffffff;
  border-radius: 50%;
  font-size: 18px;
}

#help-tab {
  padding: 0px;
  mat-tab-header {
    width: 30%;
    .mat-tab-label-container {
      .mat-tab-label-content {
        font-size: 1rem;
      }
    }
    .mat-tab-label-content {
      width: 70%;
      justify-content: flex-start;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
  mat-tab-group.mat-tab-group .mat-tab-label {
    text-align: left;
    padding: 25px;
    font-size: 1rem;
    border-bottom: 1px solid #d6d6d6;
  }
  mat-tab-group.mat-tab-group {
    .mat-tab-label-active {
      border-left: 4px solid v.$primary-orange-color;
      font-weight: 600;
      background-color: rgba(247, 149, 29, 0.2);
    }
    mat-ink-bar {
      display: none;
    }
  }
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none;
  }
  .mat-tab-label {
    height: auto;
  }
  .help-header {
    padding: 30px;
  }
  .mat-tab-body-wrapper {
    border-left: 1px solid #d6d6d6;
    padding: 30px;
  }
  #help-device-large p-accordiontab {
    .p-accordion-toggle-icon {
      position: absolute;
      left: 0em;
      top: 26%;
    }
    .pi-chevron-right:before {
      content: ' \002B';
      color: #2696d3;
      font-size: 28px;
    }
    .pi-chevron-down:before {
      content: ' \002D';
      color: #2696d3;
      font-size: 45px;
    }
    .p-accordion-header-text {
      font-size: 16px;
      font-weight: 500;
      color: #77787b;
      padding-left: 10px;
    }
  }

  #help-devices p-accordiontab {
    .pi-chevron-right:before {
      display: none;
    }
    .pi-chevron-down:before {
      display: none;
    }
    .p-accordion-tab {
      .p-accordion-header {
        .p-accordion-header-link {
          background-color: #afdef7;
          border: 1px solid #fff;
          .p-accordion-header-text {
            color: #000;
            font-size: 1rem;
            word-break: break-all;
          }
        }
      }
    }
    .p-accordion-tab-active {
      .p-accordion-header {
        .p-accordion-header-link {
          background-color: v.$primary-blue-color;
          .p-accordion-header-text {
            color: #fff;
            font-size: 1rem;
            word-break: break-all;
          }
        }
      }
    }
  }

  #help-devices-sub p-accordiontab {
    .p-accordion-tab {
      box-shadow: none;
    }
    .p-accordion-toggle-icon {
      position: absolute;
      left: 3px;
      top: 26%;
    }
    .pi-chevron-right:before {
      display: block;
      content: ' \002B';
      color: #2696d3;
      font-size: 28px;
    }
    .pi-chevron-down:before {
      display: block;
      content: ' \002D';
      color: #2696d3;
      font-size: 45px;
    }
    .p-accordion-tab {
      .p-accordion-header {
        .p-accordion-header-link {
          background-color: #fff;
          .p-accordion-header-text {
            color: #000;
            font-size: 1rem;
            padding-left: 10px;
            word-break: break-all;
          }
        }
      }
      .p-accordion-content {
        word-break: break-all;
      }
    }
    .p-accordion-tab-active {
      .p-accordion-header {
        .p-accordion-header-link {
          background-color: #fff;
          .p-accordion-header-text {
            color: #000;
            font-size: 1rem;
            word-break: break-all;
          }
        }
      }
    }
  }
}
form#help-main-portion {
  mat-form-field {
    div.mat-form-field-wrapper {
      .mat-form-field-underline {
        height: 0px !important;
      }
    }
  }
}

form#help-form {
  .p-fileupload .p-fileupload-buttonbar .p-button {
    display: none;
  }
  .p-fileupload-buttonbar {
    border: none;
    padding: 0px;
  }
  .p-fileupload-content {
    border: none;
    padding: 10px 0px;
  }
  .p-fileupload .p-progressbar {
    height: 0px;
  }
}

#help-tab {
  mat-card-content.mat-card-content {
    margin-bottom: 0px;
  }
  p-accordion {
    width: 100%;
    p-accordiontab {
      margin-top: 10px;
      .p-accordion-header-link {
        text-transform: capitalize;
      }
    }
  }
}

.galleria-image {
  border-radius: 15px 15px 15px 15px;
  height: 550px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  padding-bottom: 5px;
  width: 100%;
}

.galleria-content-image {
  display: block;
  height: 50px;
  margin-left: 20px;
  width: 100px;
}

.wrap-portion {
  word-break: break-all;
}

.goto-button.mat-mini-fab.mat-primary {
  background-color: rgb(216, 230, 243, 0.5);
  color: v.$primary-blue-color;
  @include mat.elevation(0);
}

.feedback-dialog-panel {
  .mat-dialog-container {
    border-radius: 12px;
  }
}

.polls_Outer {
  .title-section-sub-container {
    margin: 0 auto;
  }
  mat-tab-group.mat-tab-group.mat-primary {
    margin-top: -90px;
    border-top: #ccc solid 1px;
  }
  .mat-tab-labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    .mat-ripple.mat-tab-label {
      width: 50%;
      color: rgba(0, 0, 0, 1);
      opacity: 1;
    }
  }
  mat-tab-group.mat-tab-group .mat-tab-body-wrapper {
    padding-top: 0px;
    margin-top: 60px;
  }
  .addpollBx {
    width: 100%;
    mat-form-field.mat-form-field {
        display: block;
        line-height: normal;
        input.mat-input-element {
            margin-top: 0;
            font-size: 36px;
            @media(max-width:479px){
              font-size:32px;
            }
        }
    }
    textarea.mat-input-element{
        height: 120px;
        font-size: 16px;
        resize: none;
    }
  }
  .polltitle{
      .mat-form-field-infix{
          width: 100%;
      }
      span.mat-form-field-label-wrapper{
          font-size: 24px !important;
          color: #000;
          @media(max-width:479px){
            font-size: 17px !important;
          }
      }
      .mat-form-field-appearance-legacy .mat-form-field-infix {
          padding: 0;
          width: 100% !important;
      }
  }
  .addques_Right{
    mat-select.mat-select {
      height: 42px;
      border: #ccc solid 1px;
      border-radius: 4px;
      .mat-select-trigger {
        height: 42px;
      }
    }
    .mat-select-value-text{
      line-height: 42px;
      padding-left: 5px;
    }
    .mat-form-field .mat-form-field-label-wrapper{
      left: 0px;
      top: -18px;
      font-size: 16px;
      display: none;
    }
    .mat-form-field-underline{
      display: none;
    }
    .mat-form-field-infix {
      padding: 0 !important;
      border: 0 !important;
    }
  }
  .addOues{
    mat-form-field.mat-form-field {
      width: 100%;
      display: block;
      input.mat-input-element {
        margin-top: 0;
        font-size: 24px;
      }
    }
  }
  .settRight{
    .mat-form-field{
      vertical-align: top;
    }
    mat-select.mat-select {
      height: 32px;
      border: #ccc solid 1px;
      border-radius: 4px;
      .mat-select-trigger {
        height: 32px;
      }
    }
    .mat-form-field .mat-form-field-label-wrapper{
      left: 5px;
    }
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding-bottom: 0;
    }
    .mat-form-field-underline{
      display: none;
    }
    .mat-form-field-infix {
      padding: 0 !important;
      border: 0 !important;
    }
  }
  .indvSelBx{
    vertical-align: top;
    select {
      border: #ccc solid 1px;
      padding: 0;
      height: 32px;
      padding: 0 10px;
      border-radius: 5px;
    }
    .mat-form-field-appearance-legacy .mat-form-field-underline{
      display: none;
    }
    .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
      right: 5px;
      margin-top: -8px;
    }
  }
  .questOuter{
    mat-expansion-panel.mat-expansion-panel {
      border: #ccc solid 1px;
      border-radius: 5px !important;
      box-shadow: none !important;
      padding: 30px;
      margin-bottom: 30px !important;
      @media(max-width:767px){
        padding: 15px;
      }
      .mat-expansion-panel-header{
        padding: 0 !important;
        height: auto !important;
        .mat-content{
          display: block !important;
          overflow: visible !important;
          .quesOpt_Right{
            a {
              line-height: 24px;
              .mat-icon{
                vertical-align: top;
              }
            }
          }
        }
      }
      span.mat-expansion-indicator {
        display: none;
      }
      .ansList_Right{
        a {
          line-height: 24px;
          .mat-icon{
            vertical-align: top;
          }
        }
      }
    }
  }
}
.container{
  margin: 0 auto;
  max-width: 1150px;
  padding: 0 10px;
}
.userSelect {border: 1px solid #DDD; padding: 10px; border-radius: 4px;}