// Breakpoints
$xs: 'screen and (max-width: 599px)';
$sm: 'screen and (min-width: 600px) and (max-width: 959px)';
$md: 'screen and (min-width: 960px) and (max-width: 1279px)';
$lg: 'screen and (min-width: 1280px) and (max-width: 1919px)';
$xl: 'screen and (min-width: 1920px) and (max-width: 5000px)';
$lt-sm: 'screen and (max-width: 599px)';
$lt-md: 'screen and (max-width: 959px)';
$lt-lg: 'screen and (max-width: 1279px)';
$lt-xl: 'screen and (max-width: 1919px)';
$gt-xs: 'screen and (min-width: 600px)';
$gt-sm: 'screen and (min-width: 960px)';
$gt-md: 'screen and (min-width: 1280px)';
$gt-lg: 'screen and (min-width: 1920px)';

// Colors
$error-color: #f15a29;
$grey-color: #77787b;
$light-grey-color: #f8f8f8;
$header-height: 80px;

// Colors
$dark-grey-color: #666666;
$error-color: #f15a29;
$grey-color: #6c757d;
$light-blue-color: #e4f1f8;
$light-grey-color: #cccccc;
$page-padding: 15px;
$primary-blue-color: #0075bc;
$light-yellow-color: #feebd2;
$primary-orange-color: #f7941d;
